//------------------------------------------------------------------------------------

.banner-images-container {
	position: relative;
	pointer-events: none;
	overflow: hidden;
	padding: 16px calc(var(--space) - 8px) calc(var(--space) - 8px)
		calc(var(--space) - 8px);

	.banner-container {
		--aspect-ratio: 32 / 9;
		width: 100%;
		// width: 100vw;
		background-color: var(--hover);
		// aspect-ratio: var(--aspect-ratio);
		overflow: auto;
		display: grid;
		scroll-snap-type: x mandatory;
		border-radius: 10px;

		.banner {
			// width: 100vw;
			// width: 100%;
			// aspect-ratio: var(--aspect-ratio);
			overflow: hidden;
			display: grid;
			place-content: center;
			scroll-snap-align: center;

			img {
				width: 100%;
				// animation: banner-fade 5000ms ease infinite;
			}
		}
	}

	.scroll-dots-container {
		height: 40px;
		width: 100vw;
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: linear-gradient(0deg, var(--disabled), transparent);

		.scroll-dot {
			height: 8px;
			width: 8px;
			border-radius: 4px;
			background-color: var(--surface);
			box-shadow: 0 1px 2px var(--shadow);
			margin: 4px;
		}

		.scroll-dot.active {
			background-color: var(--primary);
		}
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 840px) {
	.banner-images-container {
		.banner-container {
			--aspect-ratio: 24 / 9;
			// aspect-ratio: var(--aspect-ratio);

			.banner {
				// aspect-ratio: var(--aspect-ratio);
			}
		}
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
	.banner-images-container {
		.banner-container {
			--aspect-ratio: 16 / 9;
			// aspect-ratio: var(--aspect-ratio);

			.banner {
				// aspect-ratio: var(--aspect-ratio);
			}
		}
	}
}

//------------------------------------------------------------------------------------
