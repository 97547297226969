//------------------------------------------------------------------------------------

.fallback {
    width: 100%;

    .title {
        color: var(--on-surface);
    }

    .text {
        color: var(--on-surface-variant);
        margin: calc(var(--space) / 3) 0 0 0;
    }
}

//------------------------------------------------------------------------------------