//------------------------------------------------------------------------------------

button {
	cursor: unset;
}

//------------------------------------------------------------------------------------

.navigation-button {
	height: 56px;
	width: fit-content;
	padding: 0 24px 0 8px !important;
	border-radius: 12px;
	border: none;
	outline: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: unset;
	color: var(--on-surface-variant);

	svg {
		fill: var(--on-surface-variant);
	}
}

.navigation-button.active {
	background-color: var(--primary) !important;
	color: var(--on-primary) !important;

	svg {
		fill: var(--on-primary);
	}
}

//------------------------------------------------------------------------------------

.outlined-button {
	height: 40px;
	width: fit-content;
	padding: 0 24px 0 24px;
	border-radius: 20px;
	border: 1px solid var(--outline);
	outline: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: unset;
	color: var(--primary);
}

//------------------------------------------------------------------------------------

.filled-button {
	height: 40px;
	width: fit-content;
	padding: 0 24px 0 24px;
	border-radius: 20px;
	border: none;
	outline: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: var(--primary);
	color: var(--on-primary);
}

//------------------------------------------------------------------------------------

.filled-tonal-button {
	height: 40px;
	width: fit-content;
	padding: 0 24px 0 24px;
	border-radius: 20px;
	border: none;
	outline: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: var(--secondary-container);
	color: var(--on-secondary-container);
}

//------------------------------------------------------------------------------------

.text-button {
	height: 40px;
	width: fit-content;
	padding: 0 24px 0 24px;
	border-radius: 20px;
	border: none;
	outline: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: unset;
	color: var(--primary);
}

//------------------------------------------------------------------------------------

.icon-button {
	height: 40px;
	width: 40px;
	border-radius: 20px;
	border: none;
	outline: none;
	background-color: unset;
	display: grid;
	place-content: center;

	svg {
		fill: var(--on-surface-variant);
	}
}

//------------------------------------------------------------------------------------

::-webkit-file-upload-button {
	height: 40px;
	width: fit-content;
	padding: 0 24px 0 24px;
	border-radius: 20px;
	border: 1px solid var(--outline);
	outline: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: unset;
	color: var(--primary);
}

//------------------------------------------------------------------------------------

button:disabled {
	background-color: var(--disabled) !important;
	color: var(--on-disabled);
	cursor: not-allowed;
}

button.icon-button:disabled {
	background-color: unset !important;
}

//------------------------------------------------------------------------------------

@media (hover: hover) {
	button {
		cursor: pointer;
	}

	.navigation-button:hover {
		background-color: var(--hover);
	}

	.outlined-button:hover {
		background-color: var(--hover);
	}

	.icon-button:hover {
		background-color: var(--hover);
	}

	::-webkit-file-upload-button {
		cursor: pointer;
	}
}

//------------------------------------------------------------------------------------

@media (hover: none) {
	button {
		cursor: default;
	}

	.navigation-button:active {
		background-color: var(--hover);
	}

	.outlined-button:active {
		background-color: var(--hover);
	}

	.icon-button:active {
		background-color: var(--hover);
	}
}

//------------------------------------------------------------------------------------
