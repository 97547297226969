.avatar-button {
	height: 36px;
	width: 36px;
	border-radius: 50%;
	border: none;
	outline: none;
	overflow: hidden;
	padding: 0;
	display: grid !important;
	place-content: center !important;
	box-shadow: 0 0 0 2px var(--surface), 0 0 0 4px var(--primary);
	margin: 0 0 0 var(--space) !important;

	img {
		min-height: 36px;
		width: 36px;
		background-color: var(--surface-variant);
	}
}

.avatar-dialog-container {
	height: 100vh;
	width: 100vw;
	background-color: var(--elevation);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10 !important;
	display: grid !important;
	place-content: center !important;
	animation: fade-in 300ms ease;
	backdrop-filter: blur(var(--blur));

	.avatar-dialog {
		width: min(500px, calc(100vw - (var(--space) * 2)));
		background-color: var(--surface);
		border-radius: 24px;
		box-shadow: 0 3px 6px var(--shadow), 0 12px 24px var(--shadow);
		animation: slide-forward 300ms ease;
		position: relative;

		.close-button {
			position: absolute;
			top: 8px;
			right: 8px;
		}

		.top {
			margin: var(--space);
			display: flex;
			align-items: center;
			gap: var(--space);
			border-bottom: 1px solid var(--outline);
			padding: 0 0 var(--space) 0;

			.avatar {
				height: 96px;
				width: 96px;
				border-radius: 50%;
				background-color: var(--hover);
				display: grid;
				place-content: center;
				overflow: hidden;
				box-shadow: 0 0 0 2px var(--surface), 0 0 0 4px var(--primary);

				img {
					width: 100%;
				}
			}

			.name {
				width: calc(100% - (var(--space) + 96px));
				color: var(--on-surface-variant);
			}
		}

		.foot {
			display: flex;
			align-items: center;
			// gap: var(--space);
			padding: 0 var(--space) var(--space) var(--space);

			button {
				width: 50%;
				margin: 0;
			}

			:last-child {
				margin: 0 0 0 var(--space);
			}
		}
	}
}
