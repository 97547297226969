//------------------------------------------------------------------------------------

.outlined-card {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    background-color: unset;
    border: 1px solid var(--outline);
}

//------------------------------------------------------------------------------------