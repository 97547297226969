//------------------------------------------------------------------------------------

.side-container {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: var(--space);

	.textual-instructions {
		padding: var(--space) 0 var(--space) var(--space);

		.instructions-title {
			color: var(--secondary);
			padding: 0 0 calc(var(--space) / 2) 0;
		}

		.instruction {
			color: var(--on-surface);
			padding: calc(var(--space) / 4) 0 0 0;
		}
	}

	.visual-instructions {
		width: 50%;
		padding: var(--space) var(--space) var(--space) 0;

		iframe {
			background-color: var(--hover);
		}
	}
}

.instruction-title {
	color: var(--primary);
	margin: var(--space);
	// border-bottom: 1px solid var(--outline);
	padding: 0 0 var(--space) 0;
	text-align: center;
}

.instructions-contaner {
	margin: 0 var(--space) 4px var(--space);
	padding: var(--space);
	background-color: var(--background);
	border-radius: 12px;

	.instruction {
		color: var(--on-surface-variant);
		text-align: center;
	}

	.example-text {
		color: var(--primary);
		padding: calc(var(--space) / 2) 0 var(--space) 0;
		text-align: center;
	}

	.dummy-example {
		text-align: center;
		color: var(--on-surface);
	}

	.image-container {
		height: 200px;
		width: fit-content;
		margin: auto;
		padding: 16px;
		border-radius: 12px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 100%;
			box-shadow: 0 1px 3px var(--shadow);
		}
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
	.side-container {
		grid-template-columns: auto;

		.visual-instructions {
			padding: 0 0 0 var(--space);
			width: calc(100% - var(--space));
			margin: var(--space) 0 var(--space) 0;
		}
	}
}

//------------------------------------------------------------------------------------
