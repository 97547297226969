//------------------------------------------------------------------------------------

.buffer-container {
    height: 100%;
    width: 100%;
    display: grid;
    place-content: center;
    animation: fade-in var(--transition-large) ease;

    .buffer {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        margin: auto;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 40px;
            height: 40px;
            border: 2px solid var(--primary);
            border-radius: 50%;
            animation: buffer 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: var(--primary) transparent transparent transparent;
        }

        div:nth-child(1) {
            animation-delay: -0.45s;
        }

        div:nth-child(2) {
            animation-delay: -0.3s;
        }

        div:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

//------------------------------------------------------------------------------------