//------------------------------------------------------------------------------------

.pagination-container {
    height: fit-content;
    width: fit-content;
    max-width: 100%;
    padding: var(--space) 0 0 0;
    overflow-x: auto;
    // display: flex;
    // align-items: center;
    margin: auto;
    white-space: nowrap;

    .pagination {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        margin: 0 2px 0 2px;
        cursor: pointer;
        color: var(--on-surface-variant);
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .pagination.active {
        background-color: var(--primary) !important;
        color: var(--on-primary) !important;
    }

    .pagination.previous,
    .pagination.next {
        rotate: 270deg;

        svg {
            fill: var(--on-surface-variant);
        }
    }

    .pagination.previous.disabled,
    .pagination.next.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}

//------------------------------------------------------------------------------------

@media (hover: hover) {
    .pagination-container {
        .pagination:hover {
            background-color: var(--hover);
        }
    }
}

//------------------------------------------------------------------------------------

@media (hover: none) {
    .pagination-container {
        .pagination:active {
            background-color: var(--hover);
        }
    }
}

//------------------------------------------------------------------------------------