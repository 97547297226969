//------------------------------------------------------------------------------------

.header {
	height: 64px;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
	background-color: var(--surface);
	// box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);

	.leading-container {
		padding: 0 0 0 var(--space);
		display: flex;
		align-items: center;

		.app-logo {
			height: 48px;
			width: 48px;
			margin: 0 var(--space) 0 0;
			border-radius: 50%;
			overflow: hidden;

			img {
				height: 100%;
				width: 100%;
			}
		}

		.app-title {
			h1 {
				color: var(--primary);
				margin: 0;
			}

			h2 {
				color: var(--on-surface-variant);
				margin: 0;
			}
		}
	}

	.trailing-container {
		padding: 0 var(--space) 0 0;
		display: flex;
		align-items: center;

		svg {
			fill: var(--on-surface-variant);
		}

		button {
			margin: 0 0 0 16px;
		}
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
	.header {
		.leading-container {
			.app-title {
				width: 60%;
			}
		}

		.navigation-container {
			width: 100vw;
			position: fixed;
			top: 64px;
			left: 0;
			background-color: var(--surface);
			box-shadow: 0 1px 3px var(--shadow);
			padding: 0 var(--space) var(--space) var(--space);

			.navigation-button {
				width: 100%;
				text-align: left;
				margin: var(--space) 0 0 0;
			}

			.bottom-buttons {
				margin: var(--space) 0 0 0;
				display: flex;
				flex-direction: column;
				gap: var(--space);

				button {
					width: 100%;
				}
			}
		}
	}
}

//------------------------------------------------------------------------------------
